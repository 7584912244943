<template>
  <div id="rule-editor-container">
    <iframe :id="iframeId" ref="iframeRef" :src="ruleEditorURL" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  name: 'RuleEditor',
  data() {
    return {
      iframeId: 'ruleEditorFrame',
    };
  },
  computed: {
    ruleEditorURL() { return `${window.location.protocol}//${window.location.host}${this.$route.path}/home`; },
  },
};
</script>

<style lang="scss">
  #rule-editor-container {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
